import styled from "styled-components";
import ReactPlayer from "react-player/wistia";
import { saveAs } from "file-saver";

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  padding: 24px 48px;
  @media (hover: none) {
    padding: 0 16px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: auto;
  width: 100%;
  max-width: 960px;
  row-gap: 24px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (hover: none) {
    justify-content: center;
  }
`;

const Logo = styled.img`
  height: 24px;
`;

const Button = styled.button`
  all: unset;
  padding: 8px 12px;
  color: white;
  border: 1px solid white;
  font-size: 18px;
  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
  @media (hover: none) {
    display: none;
  }
`;

const VideoWrapper = styled.div`
  width: 100%;
  position: relative;
  padding-top: 56.25%;
`;

const Video = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
`;

const Text = styled.p`
  font-size: 24px;
`;

const MobileText = styled.p`
  font-size: 24px;
  text-align: center;
  @media (hover: none) {
    display: none;
  }
`;

const Footer = styled.div`
  justify-self: flex-end;
`;

const Names = styled.span``;

function App() {
  const saveFile = () => {
    saveAs("/lcd-lightsystem.zip", "LCD-Lightsystem.zip");
  };
  return (
    <PageContainer>
      <ContentContainer>
        <Header>
          <Logo src="/lcd-lightsystem-logo.png" />
          <Button onClick={saveFile}>Download</Button>
        </Header>
        <VideoWrapper>
          <Video
            url="https://sjpetto.wistia.com/medias/e20red2zlp"
            responsive="true"
            width="100%"
            height="100%"
            controls="true"
          />
        </VideoWrapper>
        <Text>
          Do you require that radical radiance? Have you been needing that
          gnarly sheen? Introducing: LCD Lightsystem. An elegant solution for
          converting your screen into a luscious, soft LED light panel. That’s
          there when you need it the most.
        </Text>
        <Button onClick={saveFile}>Download</Button>
        <MobileText>Available on Mac.</MobileText>
      </ContentContainer>
    </PageContainer>
  );
}

export default App;
